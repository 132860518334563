import React, { useState, useEffect } from "react";
import {saveRateInfo,Post, AppData, loadCodeDescription,Label,captions,rateTypes,useTranslation,NewRate,ButtonMedium,arrowLeft,EditRateTable,Confirmation,DataSaveAlert} from "../index";

export default function EditRate(props) {
    const RateType = {
        fixed: "FIXED",
        flat: "FLATRATE",
        hourly: "HOURLY"
    };

    const [metaData] = useState(props.location.state && props.location.state.rowData ? props.location.state.rowData : []);
    const [amountDetails] = useState(props.location.state && props.location.state.amounts ? props.location.state.amounts : [])
    const [showConfirmBox, setShowConfirmBox] = useState(false);
    const [isDataDirty, setIsDataDirty] = useState(false);
    const [showAPIStatus, setShowAPIStatus] = useState(false);
    const [isApiSuccess, setIsApiSuccess] = useState(false);
    const [apiFailMsg, setApiFailMsg] = useState('');



    let initialState = {
        amountTypes: [],
        selectedProductLineCode: metaData ? metaData.productLineCode : '',
        selectedProductLineDescription: metaData ? metaData.productLineDescription : '',
        spRateList: [],
        selectedStatus: metaData ? metaData.status : '',
        selectedRateType: amountDetails && amountDetails.length > 0 ? amountDetails[0].pricingCategoryCode : RateType.fixed,
        isProductLineSelected: true,
        isFixedRate: amountDetails && amountDetails.length > 0 && RateType.fixed === amountDetails[0].pricingCategoryCode ? true : false,

    };
    const [t] = useTranslation();
    const [state, setState] = useState(initialState);

    async function onCLickStatusRadioChangeHandler(e) {
        setState({ ...state, selectedStatus: e.target.id });
        setIsDataDirty(true);
    }

    async function onCLickTypeRadioChangeHandler(e) {
        setState({ ...state, selectedRateType: e.target.id, isFixedRate: RateType.fixed === e.target.id ? true : false });
        setIsDataDirty(true);
    }


    async function onClickSaveHandler(event, object) {
        let payLoad = {};
        let amountInfoList = state.amountTypes;
        let amountTypeAPiList = [];
        if (amountInfoList && amountInfoList.length > 0) {
            amountInfoList.forEach((item: rateTypes.TAmountTemp, index: number) => {
                if (item.isAmountDirty || item.isDateDirty) {
                    let amountInfoApiData = {
                        amount: item.amount ? item.amount.toString() : "0",
                        // just for demo purpose am addinjg + 1 in day otherwise  it should be removed 
                        effectiveDate: item.effectiveDate ? item.effectiveDate : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString(),
                        amountTypeCode: item.amountTypeCode
                    }
                    amountTypeAPiList.push(amountInfoApiData);
                }
            })
        }
        // setState({ ...state, amountTypes: amountInfoList });
        payLoad = {
            serviceAdministratorId: AppData.serviceAdministratorNumber,
            accountNumber: AppData.accountNumber,
            productLineCode: state.selectedProductLineCode,
            status: state.selectedStatus,
            pricingCategoryCode: state.selectedRateType,
            amounts: amountTypeAPiList
        };
        let response: rateTypes.TResponseSaveType = await Post(saveRateInfo.subUri, {}, payLoad);
        if (response) {
            window.scrollTo(0, 0);
            if (!response.Error && !response.error) {
                 setIsApiSuccess(true);
                setIsDataDirty(false);
                if (response.amounts && response.amounts.length > 0) {
                    let oldAmounts = state.amountTypes;
                    response.amounts.forEach((apiItem, index: number) => {
                        oldAmounts.forEach((item1: rateTypes.TAmountTemp, index1) => {
                            item1.isDateDirty = false;
                            item1.isAmountDirty = false;
                            if (apiItem.amountTypeCode === item1.amountTypeCode) {
                                item1.lastAmount = apiItem.lastAmount ? Number.parseFloat(apiItem.lastAmount).toFixed(2) : '0.00';
                                item1.amount = apiItem.amount ? Number.parseFloat(apiItem.amount).toFixed(2) : '0.00';
                            }
                        });

                    });
                    setState({ ...state, amountTypes: oldAmounts });
                }
            }

                else {
                    if (response.Error) {
                        setApiFailMsg(response.Error ? response.Error.Message : null);
                        setIsApiSuccess(false);
                    }
                    if (response.error) {
                        setApiFailMsg(response.error ? response.error.description : null);
                        setIsApiSuccess(false);
                    }
                    
                }
                setShowAPIStatus(true);
                // fadeOutUpdateSuceessDiv();
            }
        }
    // async function fadeOutUpdateSuceessDiv() {
    //     setTimeout(() => {
    //         setShowAPIStatus(false)
    //     }, 10000);
    // }
    async function expiryDateChangeHanlder(date: string, id) {
        if (id && id !== undefined) {
            let amountInfoList = state.amountTypes;
            if (amountInfoList && amountInfoList.length > 0) {
                amountInfoList.forEach((item: rateTypes.TAmountTemp, index: number) => {
                    let objectId = item.amountTypeCode;
                    if (item && id === objectId) {
                        item.effectiveDate = date ? new Date(date).toISOString() : '';
                        if (item.effectiveDate !== item.tempEffectiveDate) {
                            item.isDateDirty = true;
                        } else {
                            item.isDateDirty = false;
                        }
                    }

                })
            }
            setState({ ...state, amountTypes: amountInfoList });
            setIsDataDirty(true);
        }

    }
    async function inputChangeHandler(value, objectId) {
        let amountInfoList: rateTypes.TAmountsTemp = state.amountTypes;
        if (amountInfoList && amountInfoList.length > 0) {
            amountInfoList.forEach((item: rateTypes.TAmountTemp, index: number) => {
                let tempId = item.amountTypeCode;
                if (item && objectId === tempId) {
                    item.amount = value;
                    item.isAmountValid = !isNaN(value);
                    if (value && value < 0) {
                        item.isAmountValid = false;
                    }
                    if (item.amount !== item.tempAmount) {
                        item.isAmountDirty = true;
                    } else {
                        item.isAmountDirty = false;
                    }
                }
            })
        }
        setState({ ...state, amountTypes: amountInfoList });
        setIsDataDirty(true);

    }

    useEffect(() => {
        async function fetchAmountTypesOnLoad() {
            let responseAmountTypeList: rateTypes.TResponseDataType = await Post(loadCodeDescription.subUri, {}, {
                "codeType": 17762,
                "serviceAdministratorId": AppData.serviceAdministratorId
            });
            if (responseAmountTypeList && responseAmountTypeList.Result && responseAmountTypeList.Result === 'Success') {
                let amountTypeObjList = responseAmountTypeList.codeDescriptionList;
                let newObjList = [];
                let amountList = amountTypeObjList;
                if (amountList && amountList.length > 0) {
                    amountList.forEach((item: rateTypes.TCodeDescription, index: number) => {
                        let newObj: rateTypes.TAmountTemp = {
                            amountTypeCode: item.codeValue,
                            amountTypeDescription: item.description,
                            pricingCategoryCode: '',
                            pricingCategoryDescription: '',
                            effectiveDate: '',
                            tempEffectiveDate: '',
                            amount: '',
                            tempAmount: '',
                            lastEffectiveDate: '',
                            tempLastEffectiveDate: '',
                            lastAmount: '',
                            serviceProviderId: AppData.serviceProviderId,
                            isDateDirty: false,
                            isAmountDirty: false,
                            isAmountValid: true,
                            isDateValid: true
                        }
                        if (amountDetails && amountDetails.length > 0) {
                            amountDetails.forEach((amount: rateTypes.TAmount) => {
                                if (item.codeValue === amount.amountTypeCode) {
                                    try {
                                        newObj.amount = amount.amount ? Number.parseFloat(amount.amount).toFixed(2) : '';
                                        newObj.tempAmount = amount.amount ? Number.parseFloat(amount.amount).toFixed(2) : '';
                                        newObj.lastAmount = amount.lastAmount ? Number.parseFloat(amount.lastAmount).toFixed(2) : '';
                                    }
                                    catch (Error) {

                                    }
                                    newObj.lastEffectiveDate = amount.lastEffectiveDate;
                                    newObj.tempLastEffectiveDate = amount.lastEffectiveDate;
                                    newObj.effectiveDate = amount.effectiveDate;
                                    newObj.tempEffectiveDate = amount.effectiveDate;
                                }
                            });
                        }
                        newObjList.push(newObj);
                    });
                }
                setState({ ...state, amountTypes: newObjList })
            }
        }
        fetchAmountTypesOnLoad();
    }, []);
    async function onCLickLinkBackToRates(buttonId) {
        if ('cancel' === buttonId) {
            setShowConfirmBox(true);
        }
        else {
            let amounts: rateTypes.TAmountsTemp = state.amountTypes;
            let isUnSavedData = false;
            amounts.forEach((amount: rateTypes.TAmountTemp, index: number) => {
                if (amount.isAmountDirty || amount.isDateDirty) {
                    isUnSavedData = true;
                }
            });
            if (isUnSavedData||isDataDirty) {
                setShowConfirmBox(true);
            } else {
                props.history.goBack();
            }
        }
    }
    const updateSuceesDiv = showAPIStatus && isApiSuccess ? <DataSaveAlert style={{ marginTop: '-2.5%', marginLeft: '17%' }} /> : '';
    const updateFailDiv = showAPIStatus && !isApiSuccess ? <DataSaveAlert type="failure" alertMsg={apiFailMsg} style={{ marginTop: '-2.5%', marginLeft: '17%' }} /> : '';

    return (
        <React.Fragment>

            <div className='row' >
                <div className='col-6 pl-0'>
                    <img
                        alt="ServiceBench"
                        title="ServiceBench (logo)"
                        aria-hidden="true"
                        src={arrowLeft}
                        onClick={() => { setShowConfirmBox(true) }}
                    />
                    <ButtonMedium style={{ minWidth: 0, minHeight: '20px', paddingTop: '0%',marginTop:'0%' }} labelStyle={{ paddingTop: '0%', marginTop: '0%' }} captionSize="md" variant='link' label={t(captions["lbl-link-back-rate-maintenance"])} onClick={(e) => { onCLickLinkBackToRates(null) }} />
                </div>
            </div>
            <div className="row pt-0 pb-0" style={{ marginLeft: "0.2%" }}>
                <Label label={t(captions["lbl-btn-edit-rate"])} style={{ marginTop: '0px' }} size="xlg" />
            </div>
            {updateSuceesDiv}
            {updateFailDiv}
            <NewRate
                selectedStatus={state.selectedStatus}
                statusRadioChange={onCLickStatusRadioChangeHandler}
                rateType={state.selectedRateType}
                rateTypeRadioChange={onCLickTypeRadioChangeHandler}
                captureProductLineSelect={null}
                isEditRate={true}
                detailMetaData={metaData}
                selectedProductLine={metaData.productLineDescription}
                selectedRateType={state.selectedRateType}
                isProductLineSelected={state.isProductLineSelected}
                prodLineError={""}

            ></NewRate>
            <div className='row'>
                <EditRateTable isFixedRate={state.isFixedRate} dateChangeHandler={expiryDateChangeHanlder}
                    inputChangeHandler={inputChangeHandler}
                    tableData={state.amountTypes} customStyle={{ minHeight: '90%' }} ></EditRateTable>

            </div>
           
            <div className='row pb-3'>
                <div className="col-4" />
                <div className="col-4" >
                    <ButtonMedium size='lg' variant='outline-info'
                        label={t(captions["lbl-confirm-box-btn-cancel"])}
                        onClick={() => { onCLickLinkBackToRates('cancel') }}
                        style={{ minWidth: '30%' }}></ButtonMedium>
                    <ButtonMedium size='lg'
                        label={t(captions["lbl-confirm-box-btn-save"])}
                        onClick={onClickSaveHandler}
                        style={{ minWidth: '30%', marginLeft: '10%' }} />
                </div>
                <div className="col-4" />
            </div>

            <Confirmation
                msgPart1={t(captions["confirm-box-msg-part-1"])}
                msgPart2={t(captions["confirm-box-msg-part-2"])}
                show={showConfirmBox}
                onHide={() => { setShowConfirmBox(false) }}
                actionOk={() => { setShowConfirmBox(false) }}
                actionCancel={() => { props.history.goBack() }}
            />
        </React.Fragment>
    );
}

