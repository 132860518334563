import "./App.scss";
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import {
  checkTokens,
  updateCurrentSession
} from "./modules/userSession/sessionUtility";
import { userInfo, AppData, Get, Post } from "./modules/apis/apiUtitlity";
import { useCookies } from 'react-cookie';
import { useTranslation } from './modules/utils'
import captions from './commonLabels.json';
import { loadUserProfile, loadSpDetailsLight } from "./constants/apiConst";
interface IUserProfileResponse {
  locale?: string,
  timezone?:string
}
interface ISpLoadResponse {
  providers?: IServiceProviders
}
interface IServiceProvider {
  providerId: string,
  providerName?: string,
  accountNumber?: string
}
interface IServiceProviders extends Array<IServiceProvider> { }

function App() {
  const [t, i18n] = useTranslation();
  const [isSessionExpired, setSessionExpired] = useState(false);
  const [cookies] = useCookies(['authcookie']);
  let authCookie = cookies ? cookies.authcookie : null;
  if (authCookie && authCookie.access_token) {
    userInfo.token = authCookie ? authCookie.access_token : null;
    userInfo.refreshToken = authCookie ? authCookie.refresh_token : null;
    if (checkTokens()) {
      updateCurrentSession(sessionExpired);
      const url = new URL(window.location.href);
      AppData.serviceJobNumber = url.searchParams.get("s");
      AppData.accountNumber = url.searchParams.get("accountNumber");
      AppData.serviceAdministratorId = url.searchParams.get("saId");
      AppData.serviceProviderId = url.searchParams.get("spId");
      let path = url.searchParams.get("page");
      // below code is required to create a param string which will be appended on every redirect & page manual refresh  
      let searchParams = '?';
      let params: Array<String> = url.search ? url.search.split('&') : [];
      if (params.length > 0) {
        params = params.filter((item: String) => {
          if (item && !item.match('page')) {
            return true
          }
          return false
        });
        params.forEach((item: String, index: Number) => {
          searchParams = index === 0 ? searchParams + item.replace('?', '') : searchParams + '&' + item.replace('?', '')
        });
      }
      // searchParams=`${searchParams}&page=${url.pathname.replace('/','')}`;
      userInfo.queryParams = searchParams
      path = path ? `/${path}` : `${url.pathname}`;

      if (!userInfo.firstPath) {
        userInfo.firstPath = `${path}${searchParams}`;
      }
    }
  }

  function sessionExpired() {
    setSessionExpired(true);
  }

  useEffect(() => {
    if (userInfo.token && userInfo.isAuthorized && !userInfo.isSessionExpired) {
      const loadSpDetailsLightById = async function getSpById() {
        let response: ISpLoadResponse = await Post(loadSpDetailsLight.subUri, {}, { providerId: AppData.serviceProviderId });
        if (response && response.providers && response.providers.length > 0) {
          AppData.serviceProviderName = response.providers[0].providerName;
        }

      }
      loadSpDetailsLightById();
      const getUserProfiles = async function getUserProfiles() {
        if (!userInfo.isSessionExpired && userInfo.isAuthorized) {
            let response: IUserProfileResponse = await Get(`${loadUserProfile.subUri}?userAccountId=${userInfo.userName}&companyNumber=${AppData.serviceAdministratorNumber}`, {}, {});
            if (response && response.locale) {             
              userInfo.locale=response.locale;
              userInfo.timezone=response.timezone;
              if (i18n.language !== (response.locale)) {
                console.log(`changing language from ${i18n.language} to  ${response.locale}`);
                userInfo.language = response.locale;
                i18n.changeLanguage(response.locale);
            }
          }
        }
      }
       getUserProfiles();
      userInfo.language = i18n.language;
    }
  }, [i18n]);

  if (authCookie) {
    if (userInfo.isAuthorized && !isSessionExpired) {
      return <Redirect to={userInfo.firstPath} />
    } else if (isSessionExpired || userInfo.isSessionExpired) {
      return <Redirect to={{ pathname: '/sessionTimeOut', state: { text: t(captions.auth.sessionExpired), text2: t(captions.auth.loginAgain), style: 'info' } }} />
    } else if (!userInfo.isAuthorized) {
      return <Redirect to={{ pathname: '/sessionTimeOut', state: { text: t(captions.auth.unauthorized), text2: t(captions.auth.loginAgain), style: 'info' } }} />
    }
  } else {
    return <Redirect to={{ pathname: '/sessionTimeOut', state: { text: t(captions.auth.authFailed), text2: t(captions.auth.loginAgain), style: 'info' } }} />
  }
}

export default App