const getTechPreferences = {
  subUri: '/operations/servicejobmanagement/v1/sjtechnician/technicianpreference/search',
  method: 'POST',
  apiName: 'tech-prefernce-list'
};
const saveTechPreferences = {
  subUri: '/operations/servicejobmanagement/v1/sjtechnician/technicianpreference/update',
  method: 'POST',
  apiName: 'tech-prefernce-update'
};
const technicianSearch = {
  subUri: '/servicenetwork/providersetup/v1/technician/search',
  method: 'GET',
  apiName: 'technician-search'
};
const authTokenRefresh = {
  subUri: '/authentication/refresh',
  method: 'GET',
  apiName: 'token-refresh'
};
const authenticateUser = {
  subUri: '/authentication',
  method: 'GET',
  apiName: 'authenticate-user'
};
const loadConfiguration = {
  subUri: '/usermanagementnew/client/v1/moduleconfig/search',
  method: 'POST',
  apiName: 'configuration-get'
};
const loadCodeDescription = {
  subUri: '/operations/administration/v1/codedescription/search',
  method: 'POST',
  apiName: 'code-description-search'
};
const searchCertificateName = {
  subUri: '/servicenetwork/certification/v1/management/sasearch',
  method: 'POST',
  apiName: 'search-certifications'
};
const loadSPCertifications = {
  subUri: '/servicenetwork/certification/v1/management/spsearch',
  method: 'POST',
  apiName: 'load-sp-certifications'
};
const insertUpdateSPCertifications = {
  subUri: '/servicenetwork/certification/v1/management/spupdate',
  method: 'POST',
  apiName: 'insert-update-sp-certifications'
};
const deleteSPCertifications = {
  subUri: '/servicenetwork/certification/v1/management/spdelete',
  method: 'POST',
  apiName: 'delete-sp-certifications'
};
const loadUserProfile = {
  subUri: '/operations/administration/v2/userprofile',
  method: 'POST',
  apiName: 'delete-sp-certifications'
};
const loadSPInsurances = {
  subUri: '/servicenetwork/insurance/v1/management/spsearch',
  method: 'POST',
  apiName: 'load-sp-insurances'
};
const insertUpdateSPInsurances = {
  subUri: '/servicenetwork/insurance/v1/management/spupdate',
  method: 'POST',
  apiName: 'insert-update-sp-insurance'
};
const deleteSPInsurances = {
  subUri: '/servicenetwork/insurance/v1/management/spdelete',
  method: 'POST',
  apiName: 'delete-sp-insurance'
};
const loadAuditRecords = {
  subUri: '/operations/administration/v1/audit/search',
  method: 'POST',
  apiName: 'load-audit-records'
};
const loadAuditEntity = {
  subUri: '/operations/administration/v1/audit/entitysearch',
  method: 'POST',
  apiName: 'load-auit-entity'
};
const loadProductLines = {
  subUri: '/operations/administration/v1/productline/search',
  method: 'POST',
  apiName: 'load-product-line'
};
const loadRatesList = {
  subUri: '/operations/servicejobmanagement/v1/ratemaintenance/amount/search',
  method: 'POST',
  apiName: 'load-rates-list'
};
const loadRateDetails = {
  subUri: '/operations/servicejobmanagement/v1/ratemaintenance/amount/detail',
  method: 'POST',
  apiName: 'load-rate-detail'
};

const addRateInfo = {
  subUri: '/operations/servicejobmanagement/v1/ratemaintenance/amount/add',
  method: 'POST',
  apiName: 'add-rate-info'
};
const saveRateInfo = {
  subUri: '/operations/servicejobmanagement/v1/ratemaintenance/amount/save',
  method: 'POST',
  apiName: 'save-rate-info'
};

const loadSpDetailsLight = {
  subUri: '/servicenetwork/administration/v1/spsearch',
  method: 'POST',
  apiName: 'load-sp-detail-light'
};
export {
  getTechPreferences,
  technicianSearch,
  saveTechPreferences,
  authTokenRefresh,
  authenticateUser,
  loadConfiguration,
  loadCodeDescription,
  searchCertificateName,
  loadSPCertifications,
  insertUpdateSPCertifications,
  deleteSPCertifications,
  loadUserProfile,
  loadSPInsurances,
  insertUpdateSPInsurances,
  deleteSPInsurances,
  loadAuditRecords,
  loadAuditEntity,
  loadProductLines,
  loadRateDetails,
  loadRatesList,
  addRateInfo,
  loadSpDetailsLight,
  saveRateInfo
};