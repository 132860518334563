import React, { Component } from "react";
import { Post, AppData, userInfo } from '../../../modules/apis/apiUtitlity';
import { loadCodeDescription, insertUpdateSPInsurances, loadSPInsurances, deleteSPInsurances } from '../../../constants/apiConst';
import Select from "../../../modules/components/Select";
import classes from "./Insurance.module.scss";
import * as InsuranceDataTypes from './insuranceDataTypes';

import Input from "../../../modules/components/Input";
import DatePickerMedium from "../../../modules/components/DatePickerMedium";
import ButtonMedium from "../../../modules/components/ButtonMedium";
import InsurancesTable from "./InsurancesTable";
import Label from "../../../modules/components/Label";
import { withTranslation } from 'react-i18next'
import captions from './captionsSpInsurance.json'

const config = {
    maxLimt: 5,
    maxLimitReachedErrMsg: "You have reached the maximum limit for assigning Insurances to the Service Provider ",
    entityType: 'com.servicebench.cribbage.model.genericSaMaintenance.SaSpComplianceInsurance'

};

interface IProps {
    t: Function,
    history: {
        push: Function
    }
}
class Insurance extends Component<IProps, InsuranceDataTypes.IState> {
    constructor(props) {
        super(props);
        this.state = {
            isCheckExpiry: 0,
            selectedInsuranceTypeLabel: '',
            selectedInsuranceTypeValue: '',
            insuranceTypes: null,
            insurancesList: null,
            limitAndDateInputReadOnly: true,
            selectedInsuranceLimit: "",
            selectedExpiryDate: null,
            selectedInsuranceId: '',
            active: 1,
            isEditCalled: false,
            isActionsDisabled: false,
            errors: { type: this.props.t(captions["err-insu-type"].id, captions["err-insu-type"].default), limit: '', date: '', dateTableErrorMsg: '', limitTableErrorMsg: '', errorInTable: false, insuranceTypeCode: '' },
            isMaxSelected: false
        };
        this.onInsuranceTypeSelectChange = this.onInsuranceTypeSelectChange.bind(this);
        this.onInsuranceTypeSelectChange = this.onInsuranceTypeSelectChange.bind(this);
        this.convertObjectToGenericType = this.convertObjectToGenericType.bind(this);
        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.expiryDateChangeHanlder = this.expiryDateChangeHanlder.bind(this);
        this.onClickAddHandler = this.onClickAddHandler.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.onClickEditHandler = this.onClickEditHandler.bind(this);
        this.onClickSaveHandler = this.onClickSaveHandler.bind(this);
        this.insertUpdateInsurances = this.insertUpdateInsurances.bind(this);
        document.title = this.props.t(captions["lbl-title"].id, captions["lbl-title"].default);
    }
    convertObjectToGenericType(object: any, labelKey: string, valueKey: string, inputType: string) {
        if (object && object.length > 0) {
            let newObj: any = [];
            object.forEach(function (item: any, index) {
                let newItem = {
                    label: item[labelKey],
                    value: item[valueKey],
                    data: item
                };
                newObj.push(newItem);
            });
            return newObj;
        }
        else { return {} }
    }

    onInsuranceTypeSelectChange(e) {
        let index = e.nativeEvent.target.selectedIndex;
        let label = e.nativeEvent.target[index].text;
        let value = e.target.value;
        let { errors, selectedInsuranceTypeValue, selectedInsuranceTypeLabel, limitAndDateInputReadOnly
        } = this.state;
        if (value && value !== "") {
            errors.type = '';
            errors.date = '';
            errors.limit = '';
            selectedInsuranceTypeValue = value;
            selectedInsuranceTypeLabel = label;
            limitAndDateInputReadOnly = false;
        }
        else {
            errors.type = this.props.t(captions["err-req-type"].id, captions["err-req-type"].default);
            errors.limit = '';
            errors.date = '';
            selectedInsuranceTypeValue = '';
            selectedInsuranceTypeLabel = '';
            limitAndDateInputReadOnly = true;
        }
        this.setState({
            selectedInsuranceTypeValue,
            selectedInsuranceTypeLabel,
            limitAndDateInputReadOnly,
            selectedInsuranceId: '',
            selectedInsuranceLimit: '',
            isCheckExpiry: 0,
            selectedExpiryDate: null,
            errors

        });
    }

    inputChangeHandler(value, objectId) {
        //here we are updating the  insurancesList object in the state whenever the userinput is changed on UI

        if (!objectId) { // when the record doesnt exist in the DB ie Insert
            let { errors } = this.state;
            this.setState({
                selectedInsuranceLimit: value ? value : '',
                errors: errors
            });
        }
        else if (objectId) {// only in case of update
            let insurList: InsuranceDataTypes.TInsurances = this.state.insurancesList;
            if (insurList && insurList.length > 0) {
                insurList.forEach((item: InsuranceDataTypes.TInsurance, index: number) => {
                    let tempId = item.insuranceTypeCode;
                    if (item && objectId === tempId) {
                        item.policyLimit = value ? value : '';
                    }
                })
            }
            this.setState({ insurancesList: insurList });
        }
    }

    expiryDateChangeHanlder(date: Date, id) {
        // console.log("date from date picker ", date);
        let { errors, isCheckExpiry } = this.state;
        if (id && id !== undefined) {
            let insurList = this.state.insurancesList;
            if (insurList && insurList.length > 0) {
                insurList.forEach((item: InsuranceDataTypes.TInsurance, index: number) => {
                    let objectId = item.insuranceTypeCode;
                    if (item && id === objectId) {
                        item.expirationDate = date ?new Date(date).toISOString() : '';
                    }
                })
            }
            this.setState({ insurancesList: insurList });
        }
        else if (date) {
            errors.date = '';
            this.setState({ selectedExpiryDate: date, errors: errors });
        }
        else if (!date) {
            if ((isCheckExpiry === 1 || '1' === isCheckExpiry) && this.state.selectedInsuranceId) {
                errors.date = this.props.t(captions["err-req-exp-date"].id, captions["err-req-exp-date"].default)
                this.setState({ selectedExpiryDate: date, errors: errors });
            }
            else {
                this.setState({ selectedExpiryDate: null, errors: errors });
            }
        }
    }

    async insertUpdateInsurances(list) {
        let data = null;
        let insuranceList;

        if (list && Array.isArray(list)) {
            insuranceList = list;
        }
        else {
            insuranceList = [list];
        }

        data = {
            accountNumber: AppData.accountNumber,
            serviceAdministratorNumber: AppData.serviceAdministratorNumber,
            insuranceList: insuranceList
        };
        let response: InsuranceDataTypes.TResponseDataType = await Post(insertUpdateSPInsurances.subUri, null, data);
        if (response) {
            if (response.Result && response.Result === 'Error') {
                console.log('error while saving insurances');
            }
        }
    }

    onClickAddHandler() {
        let allInputAvailable = true;
        let { errors } = this.state;
        let checkExpiryForCode;
        if (!this.state.selectedInsuranceTypeValue || this.state.selectedInsuranceTypeValue === '') {
            allInputAvailable = false;
            errors.type = this.props.t(captions["err-req-type"].id, captions["err-req-type"].default)
        } //if the selectedInsuranceTypeValue is not blank, only then validate the values of selectedInsuranceLimit and expiry
        else {

            let existingInsurancesList: InsuranceDataTypes.TInsurances = this.state.insurancesList;
            let isDuplicate = false;
            let selected = this.state.selectedInsuranceTypeValue;
            if (existingInsurancesList !== null && existingInsurancesList.length > 0) {
                existingInsurancesList.forEach(function (item: InsuranceDataTypes.TInsurance, index) {
                    if (item.insuranceTypeCode === selected) {
                        isDuplicate = true;
                        allInputAvailable = false;
                    }
                });
            }

            if (isDuplicate) {
                errors.type = this.props.t(captions["err-duplicate-type"].id, captions["err-duplicate-type"].default);
            }
            else {

                if (!this.state.selectedInsuranceLimit || this.state.selectedInsuranceLimit === '') {
                    allInputAvailable = false;
                    errors.limit = this.props.t(captions["err-req-limit"].id, captions["err-req-limit"].default);
                } else {
                    errors.limit = '';
                    const regEx = /^[0-9\b]+$/;
                    if (!regEx.test(this.state.selectedInsuranceLimit.toString()) || this.state.selectedInsuranceLimit > 10000000 || this.state.selectedInsuranceLimit < 1) {
                        allInputAvailable = false;
                        errors.limit = this.props.t(captions["err-max-insuLimit"].id, captions["err-max-insuLimit"].default);
                    }
                }

                let insuranceTypesList: InsuranceDataTypes.TCodeDescriptions = this.state.insuranceTypes;
                insuranceTypesList.forEach(function (item: InsuranceDataTypes.TCodeDescription) {
                    if (item.codeValue === selected) {
                        checkExpiryForCode = item.codeValue3;
                    }
                });
                // if (this.state.isCheckExpiry && (this.state.isCheckExpiry === 1 || '1' === this.state.isCheckExpiry) && (!this.state.selectedExpiryDate || this.state.selectedExpiryDate === undefined)) {
                //     allInputAvailable = false;
                //     errors.date = this.props.t(captions["err-req-exp-date"].id, captions["err-req-exp-date"].default);
                // }
                if (checkExpiryForCode && (checkExpiryForCode === 1 || '1' === checkExpiryForCode) && (!this.state.selectedExpiryDate || this.state.selectedExpiryDate === undefined)) {
                    allInputAvailable = false;
                    errors.date = this.props.t(captions["err-req-exp-date"].id, captions["err-req-exp-date"].default);
                }
            }
        }

        if (allInputAvailable) {
            let correctedDate = null
            if (this.state.selectedExpiryDate) {
                correctedDate = new Date(this.state.selectedExpiryDate).toISOString()
            }

            let newObj: InsuranceDataTypes.TInsurance = {
                expirationDate: correctedDate ? correctedDate : '',
                policyLimit: this.state.selectedInsuranceLimit,
                insuranceTypeCode: this.state.selectedInsuranceTypeValue
            };
            let obj = {
                expirationDate: correctedDate ? correctedDate : '',
                policyLimit: this.state.selectedInsuranceLimit,
                insuranceTypeCode: this.state.selectedInsuranceTypeValue,
                activePolicy: true
            }

            this.insertUpdateInsurances(obj);
            newObj.insuranceTypeDescription = this.state.selectedInsuranceTypeLabel;
            newObj.checkExpiration = checkExpiryForCode;
            newObj.insuranceId = this.state.selectedInsuranceId;
            let updatedInsListData: InsuranceDataTypes.TInsurances = [];
            let { isMaxSelected, isActionsDisabled } = this.state;

            if (this.state.insurancesList) { updatedInsListData = [newObj, ...this.state.insurancesList]; }
            else {
                updatedInsListData = [newObj];
            }
            errors.type = this.props.t(captions["err-insu-type"].id, captions["err-insu-type"].default)
            if (updatedInsListData && updatedInsListData.length >= config.maxLimt) {
                isMaxSelected = true;
                errors.type = '';
                isActionsDisabled = true;
            }
            errors.limit = '';
            errors.date = '';
            this.setState({
                insurancesList: updatedInsListData,
                selectedInsuranceTypeValue: '',
                selectedInsuranceTypeLabel: '',
                selectedExpiryDate: undefined,
                selectedInsuranceLimit: '',
                limitAndDateInputReadOnly: true,
                isMaxSelected,
                isActionsDisabled
            })
        } else {
            this.setState({ errors: errors });
        }
    }
    onClickEditHandler(event) {
      //  let { insurancesList}= this.state; 
        this.setState({ isEditCalled: true, isActionsDisabled: true });    
    }

    onClickSaveHandler(event, object) {
        let { isMaxSelected, isEditCalled, insurancesList, errors, isActionsDisabled, selectedInsuranceTypeValue } = this.state;
        isActionsDisabled = true;
        let isValidationFailed = false;
        if (insurancesList) {
            if (insurancesList.length > 0) {
                let list = [];
                insurancesList.forEach((item: InsuranceDataTypes.TInsurance, index) => {
                    if (!item.policyLimit || item.policyLimit === '') {
                        isValidationFailed = true;
                        isEditCalled = true;
                    } else {
                        const regEx = /^[0-9\b]+$/;
                        if (!regEx.test(item.policyLimit.toString()) || item.policyLimit > 10000000 || item.policyLimit<1) {
                            isValidationFailed = true;
                            isEditCalled = true;
                       }
                    }
                    if (!item.expirationDate && (item.checkExpiration === '1' || item.checkExpiration === 1)) {
                        isValidationFailed = true;
                        isEditCalled = true;
                    }
                    let obj = {
                        expirationDate: item.expirationDate ? item.expirationDate : '',
                        policyLimit: item.policyLimit,
                        insuranceTypeCode: item.insuranceTypeCode,
                        activePolicy: true
                    }
                    list.push(obj);                    
                })
                if (!isValidationFailed) {
                    isEditCalled = false;
                    isActionsDisabled = false;
                    this.insertUpdateInsurances(list);
                    if (insurancesList.length >= config.maxLimt) {
                        isMaxSelected = true;
                        errors.type = '';
                        isActionsDisabled = true;
                    }
                    else {
                        errors.type = selectedInsuranceTypeValue ? '' : this.props.t(captions["err-insu-type"].id, captions["err-insu-type"].default);
                        isMaxSelected = false;
                    }
                }
            }
        }
        this.setState({ isEditCalled, isActionsDisabled, isMaxSelected, errors });
    }

    async handleDelete(deletedItem: InsuranceDataTypes.TInsurance) {
        if (deletedItem) {
            let avlData: InsuranceDataTypes.TInsurances = null;
            let targetCombinedKey = deletedItem.insuranceTypeCode;
            avlData = this.state.insurancesList.filter((item, index) => {
                let itemCombinedKey = item.insuranceTypeCode;
                return targetCombinedKey !== itemCombinedKey
            });
            let payload = { "serviceAdministratorNumber": `${AppData.serviceAdministratorNumber}`, "accountNumber": `${AppData.accountNumber}`, "insuranceTypeCode": `${deletedItem.insuranceTypeCode}` }
            let response: InsuranceDataTypes.TResponseDataType = await Post(deleteSPInsurances.subUri, {}, { ...payload });
            let { isEditCalled, isActionsDisabled } = this.state;
            if (!avlData || (avlData && avlData.length === 0)) {
                isActionsDisabled = false;
                isEditCalled = false;
            }
            this.setState({ insurancesList: avlData, isActionsDisabled, isEditCalled });
            if (response && response.Result && response.Result === 'Failure') {
                throw new Error('delete failed from handleDelete method from Insurances ');

            }
        }
    }
    async componentDidMount() {
        let responseInsuranceType: InsuranceDataTypes.TResponseDataType = await Post(loadCodeDescription.subUri, {}, {
            "codeType": 64002,
            "serviceAdministratorId": AppData.serviceAdministratorId
        });
        if (responseInsuranceType && responseInsuranceType.Result && responseInsuranceType.Result === 'Success') {
            this.setState({
                insuranceTypes: responseInsuranceType.codeDescriptionList

            });
        }
        let responseSpInsList: InsuranceDataTypes.TResponseDataType = await Post(loadSPInsurances.subUri, {}, {
            accountNumber: AppData.accountNumber, serviceAdministratorId: AppData.serviceAdministratorId
        });
        if (responseSpInsList && responseSpInsList.Result && responseSpInsList.Result === 'Success') {
            let { isMaxSelected, errors, isActionsDisabled } = this.state;
            if (responseSpInsList.insuranceList && responseSpInsList.insuranceList.length >= config.maxLimt) {
                isMaxSelected = true;
                errors.type = '';
                isActionsDisabled = true;
            }
            this.setState({
                insurancesList: responseSpInsList.insuranceList,
                isMaxSelected: isMaxSelected,
                errors: errors,
                isActionsDisabled
            });
        }
    }
    render() {
        let { errors } = this.state;
        let { isActionsDisabled } = this.state;
        let insurances = null;
        const { t } = this.props;
        const auditData = { entityType: config.entityType, columnName: 'insurance' };
        if (this.state.insurancesList && this.state.insurancesList.length > 0) {
            insurances = (
                <React.Fragment>
                    <div className="row" style={{ borderTop: '1px groove', marginTop: '0.5%' }}>
                        <div className='col-10'><Label size="lg" label={t(captions["lbl-added-insurances"].id, captions["lbl-added-insurances"].default)} /></div>
                        {this.state.insurancesList && this.state.insurancesList.length > 0 ?
                            <div className='col-2'>
                                {this.state.isEditCalled ?
                                    <ButtonMedium label={t(captions["lbl-btn-save"].id, captions["lbl-btn-save"].default)}
                                        onClick={this.onClickSaveHandler} variant='link'
                                        style={{ maxHeight: '', marginLeft: '1%,min' }} /> :

                                    <ButtonMedium label={t(captions["lbl-btn-edit"].id, captions["lbl-btn-edit"].default)} onClick={this.onClickEditHandler}
                                        variant='link' style={{ maxHeight: '', marginLeft: '1%,min' }} />}
                            </div>
                            : <React.Fragment></React.Fragment>}
                    </div>
                    <div className="row">
                        <InsurancesTable dateChangeHandler={this.expiryDateChangeHanlder}
                            isEditCalled={this.state.isEditCalled}
                            inputChangeHandler={this.inputChangeHandler}
                            errors={this.state.errors}
                            tableData={this.state.insurancesList} handleDelete={this.handleDelete}></InsurancesTable>
                    </div>
                </React.Fragment>
            );
        }
        return (
            <div className={classes["insurance"]}>
                <div className={`row ${classes['title']}`} >
                    <div className="col-10" >
                        <label>{`${t(captions["lbl-title"].id, captions["lbl-title"].default)}`} </label>
                    </div>
                    <div className="col-2">
                        {/* <Link */}
                        <ButtonMedium size='lg' textDecorationLine='underline' label={`${t(captions["lbl-btn-audit-insurance"].id, captions["lbl-btn-audit-insurance"].default)}`} onClick={() => { this.props.history.push(`/audit${userInfo.queryParams}`, auditData) }} variant='link' style={{ maxHeight: '', marginLeft: '10%,min' }}></ButtonMedium>
                    </div>
                </div>
                <div className="row"  >
                    <div className="col-4">
                        <Label label={t(captions["lbl-type"].id, captions["lbl-type"].default)} />
                        <Select disabled={isActionsDisabled}
                            value={this.state.selectedInsuranceTypeValue}
                            onChange={this.onInsuranceTypeSelectChange}
                            options={this.state.insuranceTypes ? this.convertObjectToGenericType(
                                this.state.insuranceTypes, 'description', 'codeValue', 'select'
                            ) : { label: '', value: '' }}
                        />
                    </div>

                    <div className="col-4">
                        <div>
                            <Label label={t(captions["lbl-limit"].id, captions["lbl-limit"].default)} />
                        </div>
                        <div >
                            <Input
                                name={"REF_INPUT"}
                                onChange={this.inputChangeHandler}
                                value={this.state.selectedInsuranceLimit}
                                readOnly={this.state.limitAndDateInputReadOnly}
                                disabled={this.state.isActionsDisabled}
                            ></Input>
                        </div>
                    </div>
                    <div className="col-4">
                        <div >
                            <Label label={t(captions["lbl-exp-date"].id, captions["lbl-exp-date"].default)} />
                        </div>
                        <div>
                            <DatePickerMedium
                                handleChange={this.expiryDateChangeHanlder}
                                readOnly={this.state.limitAndDateInputReadOnly}
                                minDate={new Date()}
                                selectedDate={this.state.selectedExpiryDate}
                                disabled={this.state.isActionsDisabled}
                                fieldLabel={t(captions["lbl-exp-date"].id, captions["lbl-exp-date"].default)}
                            ></DatePickerMedium>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ height: 18, marginTop: '0.3%' }}>
                    <div className="col-4" >
                        {errors.type ? <span style={{ color: 'red' }}>{errors.type}</span> : <React.Fragment />}
                    </div >
                    <div className="col-4">
                        {errors.limit ? <span style={{ color: 'red' }}>{errors.limit}</span> : <React.Fragment />}

                    </div>
                    <div className="col-4"  >
                        {errors.date ? <span style={{ color: 'red' }}>{errors.date}</span> : <React.Fragment />}

                    </div>
                </div>
                <div className="row" style={{ marginLeft: '0%' }}>
                    <ButtonMedium label={t(captions["lbl-btn-add-to-list"].id, captions["lbl-btn-add-to-list"].default)} disabled={this.state.isActionsDisabled} variant='info'
                        style={{ minWidth: '8%' }} onClick={this.onClickAddHandler} />
                </div>
                {this.state.isMaxSelected ? <Label label={config.maxLimitReachedErrMsg} style={{ color: 'red', marginTop: '1%' }} /> : <React.Fragment />}
                {this.state.insurancesList && this.state.insurancesList.length > 0 ? insurances : <span style={{ color: 'red', marginTop: '20%' }}>{t(captions["lbl-no-insurance-added"].id, captions["lbl-no-insurance-added"].default)} </span>}
            </div>
        );
    }
}

export default withTranslation()(Insurance)