import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "./i18n";

import App from "./App";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from 'react-router-dom';
import Routes from "./modules/router";
import 'bootstrap/dist/css/bootstrap.min.css';
ReactDOM.render((
  <Suspense fallback>
    <BrowserRouter >
      <App/>
      <Routes/>
    </BrowserRouter>
  </Suspense>
), document.getElementById('root'));

// ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

