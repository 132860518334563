import { AppData, Post } from "../apis/apiUtitlity";
import { loadSpDetailsLight } from "../../constants/apiConst";

interface ISpLoadResponse {
    providers?: IServiceProviders
}
interface IServiceProvider {
    providerId: string,
    providerName?: string,
    accountNumber?: string
}
interface IServiceProviders extends Array<IServiceProvider> { }
export const getSpName = async () => {
    if (AppData && AppData.serviceProviderName) {
        return Promise.resolve(AppData.serviceProviderName)
    }
    let response: ISpLoadResponse = await Post(loadSpDetailsLight.subUri, {}, { providerId: AppData.serviceProviderId });
    if (response && response.providers) {
        AppData.serviceProviderName = response.providers[0].providerName
        return Promise.resolve(AppData.serviceProviderName)
    }
}
